import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const GallerySlider = () => {
  const submittedPhotos = useStaticQuery(graphql`
    query GallerySliderTwoPhotoSubmissionsQuery {
      allFile(filter: { relativeDirectory: { eq: "photos" } }) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                caption
                is_caption
                submitted_by
                submitted_via
                photo {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allFile.edges;

  return (
    <CarouselProvider
      totalSlides={submittedPhotos.length}
      naturalSlideHeight={150}
      naturalSlideWidth={200}
      isPlaying
      interval={4000}
      infinite
    >
      <Slider>
        {submittedPhotos.map(({ node }, i) => (
          <Slide
            key={node.id}
            index={i}
            innerClassName={"flex flex-col justify-center"}
          >
            <Img
              fluid={
                node.childMarkdownRemark.frontmatter.photo.childImageSharp.fluid
              }
              imgStyle={{ pointerEvents: "none" }}
              alt={node.childMarkdownRemark.frontmatter.title}
              loading={"eager"}
            />
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  );
};

export default GallerySlider;
